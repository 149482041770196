<template>
  <div class="user-box">
    <!-- 顶部搜索 -->
    <search-form @handleData="handleData"></search-form>
    <!-- 黑名单表单 -->
    <el-table border :data="list" style="width: 100%" :row-style="{ height: 0 }" :cell-style="{ padding: '8px 0' }">
      <el-table-column prop="tempId" label="序号" align="center" width="80" show-overflow-tooltip />
      <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip />
      <el-table-column prop="idCard" label="身份证" min-width="160" align="center" />
      <el-table-column prop="mobile" label="手机号" min-width="120" align="center" />
      <el-table-column prop="auths" label="认证状态" min-width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.auths" :key="item" effect="dark" size="small"
            :type="item == '未认证' ? 'info' : 'success'">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="creator" label="录入人" min-width="120" align="center" /> -->
      <el-table-column prop="reason" label="备注" align="center" />
      <el-table-column label="操作" min-width="95" align="center" fixed="right">
        <template slot-scope="scope" style="display: flex; padding: 0 0 0 10px;">
          <!-- 移出 -->
          <el-button :disabled="!scope.row.edit" type="danger" size="mini" icon="el-icon-delete" slot="reference" circle
            @click="del(scope.row)"></el-button>
          <el-button :disabled="!scope.row.edit" type="info" size="mini" icon="el-icon-edit-outline" circle
            @click="handleRemarks(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页管理 -->
    <el-pagination background :page-sizes="sizes" :page-size="10" :current-page="page.current"
      layout="total, sizes, prev, pager, next" :total="page.total" @size-change="changeSize"
      @current-change="changePage"></el-pagination>
    <!--新增编辑弹窗-->
    <add-form ref="addFormRef" @refresh="init"></add-form>
  </div>
</template>

<script>

import service from '@/api/common'
import pagination from '@/mixins/pagination-plus'
import view from '@/components/viewBar/view.js'
// components
import searchForm from './search-form'
import addForm from './add-form'
import verify from '@/utils/verify'

export default {
  mixins: [pagination, view],
  data () {
    return {
      prefix: "api/blacklist",
      list: [],
      showRisk: true
    };
  },
  components: {
    searchForm, addForm
  },
  methods: {
    // 初始化
    init () {
      this.getList()
    },
    // 获取黑名单列表
    async getList () {
      if (!this.page.params) {
        this.page.params = {}
      }
      // 重置创建人过滤信息
      this.page.params.createBy = null
      if (!this.page.params.name && !this.page.params.idCard && !this.page.params.mobile) {
        this.page.params.createBy = JSON.parse(sessionStorage.getItem("xinyou_sh_user")).id
      }
      const res = await service.page(this.prefix, this.page);
      this.page = this.parsePage(res)
      this.list = this.parseList(res)
    },
    // 处理工具栏
    handleData (params) {

      switch (params.type) {
        case -1:  // 重置搜索栏数据
          this.search(params)
          break;
        case 0: // 新增黑名单
          this.addOrEdit(params)
          break;
        case 1: // 查询自己包括其他用户的黑名单里面的数据
          this.search(params)
          break;
        default:
          break;
      }
    },
    search (params) {
      this.page.current = 1
      let searchData = Object.assign(params);
      this.page.params = searchData
      return this.getList();
    },
    addOrEdit () {
      this.$refs.addFormRef.open(true)
    },
    // 移除黑名单
    del (row) {
      this.$confirm(`是否确认将用户<span style="color:red">${row.name}</span>永久性移出黑名单?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        let result = await service.del(this.prefix, row.id)
        this.$notify({ type: 'success', message: '移出成功' })
        this.init();
      })
    },
    handleRemarks (row) {
      this.$prompt('请输入备注信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async ({ value }) => {
        let result = await service.edit(this.prefix, { id: row.id, reason: value })
        row.reason = value
        this.$notify({ type: 'success', message: '操作成功' })
      })
    },
    openReport (row) {
      // 打开报告界面
      const { href } = this.$router.resolve({
        path: `/risk/detail`,
        query: { flId: row.userId }
      })
      window.open(href, '_blank')
    }
  },
  mounted () {
    this.init()
  }
};
</script>

<style lang="scss"></style>
