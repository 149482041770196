<template>
  <!-- 顶部搜索栏 -->
  <div class="search">
    <el-form :inline="true" :model="form" ref="search" :rules="rules">
      <el-form-item prop="name">
        <el-input v-model.trim="form.name" class="id-name" clearable placeholder="">
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="idCard">
        <el-input v-model.trim="form.idCard" class="id-card" clearable placeholder="">
          <template slot="prepend">身份证</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model.trim="form.mobile" class="id-mobile" clearable placeholder="">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="handleData(1)">搜索</el-button>
        <el-button size="small" @click="handleData(-1)">重置</el-button>
        <el-button size="small" type="success" @click="handleData(0)">录入</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import verify from '@/utils/verify'

export default {
  data () {
    var checkIdCard = (rule, value, callback) => {
      if (!value || value === '') {
        callback()
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'))
      }
      callback();
    };
    var checkMobile = (rule, value, callback) => {
      if (!value || value === '') {
        callback()
      } else if (!verify.mobile(value)) {
        callback(new Error('请输入正确的手机号'))
      }
      callback()
    }

    return {
      form: { type: 0, name: null, mobile: null, idCard: null },
      rules: {
        idCard: [{ validator: checkIdCard, trigger: 'blur' }],
        mobile: [{ validator: checkMobile, trigger: 'blur' }]
      },
    }
  },
  methods: {
    handleData (type) {
      if (type == -1) {
        this.form = { name: null, mobile: null, idCard: null }
      } else if (type == 1) {
        let check = false;
        this.$refs.search.validate(res => (check = res))
        if (!check) return
      }
      this.form.type = type
      this.$emit('handleData', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
.query-btn {
  background-color: #1ecab8;
  border: 1px solid #1ecab8;
  color: #fff;
}

.search {
  &-form {
    overflow: hidden;
    text-align: left;
    padding: 10px 0 15px 0;
    // border-bottom: 1px solid #eee;
  }
}

@media screen and (max-width: 480px) {
  .search {
    height: 100%;

    .search-form {
      height: 100%;

      .el-row--flex {
        display: block !important;

        .el-col-4 {
          width: 100%;
        }

        .el-col-6 {
          width: 100%;
          margin-top: 1rem;
        }

        .el-col-3 {
          margin-top: 1rem;
          width: 50%;
        }

      }
    }

    .bot {
      margin-bottom: 2rem;
      width: 8rem;
      float: left;
    }
  }

}
</style>
