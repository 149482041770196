import { mapState, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("userReport", ["showView"])
  },
  methods: {
    ...mapMutations("userReport", ["setShowView", "setIndex","setBalance","setUid","setAuditStatus", "setMemo", "setFlId", "setDebtStatus"]),
    ...mapActions("userReport/verify", ["userInfo","getVerify"]),
    // 查看
    handleView(row, check = true) {
      if (row.amount < 50) {
        this.$alert('账户余额不能低于50元，请尽快充值!', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
            this.$router.push({ path: "/charge" });
          }
        });
      } else {
        if (!check || row.verify_status.real_name) {
          // 存储uid
          this.setUid(row.userId)
          this.setBalance(row.balance)
          // 存储审核状态
          this.setAuditStatus(row.status)
          // 存储资产负债认证状态
          this.setDebtStatus(row.verify_status.assets_info)
          return this.getVerify()
        }

      }
    },
    // 关闭查看弹窗
    closeView() {
      this.setShowView(false);
      this.setIndex();
    }
  },
  mounted() {
    this.setIndex();
    // 切换导航页面时关闭弹框
    this.setShowView(false);
  }
};
